.wrapper {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-align: stretch;
      align-items: stretch; }
  .wrapper .sidebar {
    min-width: 64px;
    max-width: 64px;
    height: 100%;
    background: #f6f6f6;
    color: #fff; }
  .wrapper .content {
    height: 100%;
    overflow-y: scroll;
    -ms-flex: 1;
        flex: 1;
    background-color: #ffffff; }
  .wrapper .sidebar-primary {
    position: fixed;
    background: #0f1b4a;
    width: 64px;
    height: 100vh; }
    .wrapper .sidebar-primary .sidebar-header {
      background: #0f1b4a;
      height: 64px;
      margin-bottom: 40px; }
      .wrapper .sidebar-primary .sidebar-header img {
        height: 40px;
        width: 40px;
        margin: 12px; }
    .wrapper .sidebar-primary ul li {
      opacity: 0.5; }
    .wrapper .sidebar-primary ul li.active {
      opacity: 1.0; }
    .wrapper .sidebar-primary ul li a,
    .wrapper .sidebar-primary ul li a:visited {
      display: block;
      color: #373a3c; }
    .wrapper .sidebar-primary ul li a:hover {
      color: #373a3c;
      background: #0f1b4a;
      text-decoration: none;
      cursor: pointer; }
    .wrapper .sidebar-primary ul li a img {
      height: 24px;
      width: 24px;
      margin: 20px; }
    .wrapper .sidebar-primary ul li .fa {
      width: 24px;
      height: 24px; }
    .wrapper .sidebar-primary ul li.active > a, .wrapper .sidebar-primary a[aria-expanded='true'] {
      color: #373a3c;
      background: #0f1b4a; }
    .wrapper .sidebar-primary ul li.user {
      position: absolute;
      bottom: 8px; }
      .wrapper .sidebar-primary ul li.user img {
        height: 40px;
        width: 40px;
        margin: 12px; }
  .wrapper .sidebar-secondary {
    position: fixed;
    margin-top: -24px;
    min-width: 240px;
    max-width: 240px;
    left: 64px;
    height: 100vh;
    overflow-y: scroll;
    background-color: #f6f6f6; }
    .wrapper .sidebar-secondary .sidebar-header {
      height: 64px; }
      .wrapper .sidebar-secondary .sidebar-header h3 {
        color: #373a3c;
        height: 40px;
        margin: 12px; }
      .wrapper .sidebar-secondary .sidebar-header h5 {
        font-size: 20px;
        color: #373a3c;
        margin: 24px; }
    .wrapper .sidebar-secondary ul p {
      color: #373a3c;
      padding: 0px 9px 0px 24px;
      text-transform: uppercase;
      font-weight: lighter;
      font-size: 0.7em;
      margin-top: 40px;
      font-weight: bold; }
    .wrapper .sidebar-secondary ul li a,
    .wrapper .sidebar-secondary ul li a:visited {
      padding: 8px;
      display: block;
      color: #373a3c;
      margin: 2px 17px;
      border-radius: 4px;
      font-size: 14px; }
    .wrapper .sidebar-secondary ul li a:hover {
      background: #eaeaea;
      color: #373a3c;
      text-decoration: none;
      cursor: pointer; }
    .wrapper .sidebar-secondary ul li .fa {
      width: 20px; }
    .wrapper .sidebar-secondary ul li.active > a, .wrapper .sidebar-secondary a[aria-expanded='true'] {
      color: #373a3c;
      background: #eaeaea; }
  .wrapper .container-with-sidebar {
    display: -ms-flexbox;
    display: flex; }
    .wrapper .container-with-sidebar .container {
      max-width: 100%;
      padding-left: 274px;
      padding-right: 20px; }
