.arrow-right-border-fill {
  padding-left: 14px;
  padding-right: 14px;
  border-radius: 22px;
  text-align: center; }

.arrow-right-border-fill-lifecycle {
  border-radius: 22px;
  text-align: center;
  width: 38px; }

.row-form-spacing {
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 15px; }

.card-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  background-color: '#F6F6F6';
  font-weight: bold;
  font-size: 16px;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  height: 50px; }

.add-notification {
  padding-left: 0px;
  margin-left: 0px;
  padding-top: 0px;
  padding-bottom: 0px; }

.remove-rule-button {
  float: right;
  padding-right: 0px;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: -20px; }

.remove-notification-button {
  float: right;
  padding-right: 0px;
  padding-top: 0px;
  margin-top: 0px; }

.rule-header {
  padding-top: 14px;
  margin-bottom: -4px; }

.grey-background {
  background-color: '#F6F6F6' !important; }

.white-background {
  background-color: 'white' !important; }

.remove-button-wrapper {
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 0px; }

.modal-header-close-button {
  position: absolute;
  height: 54px;
  right: 22px;
  padding-top: 14px;
  padding-left: 20px;
  border-left: 1px #dedede solid; }

.bread-crumbs-container {
  padding-left: 10px;
  padding-right: 10px;
  background: #a5ceff;
  border-radius: 20px;
  margin-right: 10px;
  margin-left: 10px;
  min-width: 80px;
  text-align: center;
  color: #3d3d3d; }

.modal-header-title {
  font-weight: bold;
  font-size: 16px;
  padding-left: 24px;
  margin-right: 14px; }

.completed-card-background-color-grey {
  background-color: #DEDEDE;
  display: inline-block; }

.completed-card-background-color-blue {
  background-color: #4078FA;
  display: inline-block;
  color: white; }

.completed-card-background-color-green {
  background-color: #1FC881;
  color: white;
  display: inline-block; }

.heading-badge {
  background-color: #E04870;
  color: #F8F8F0;
  display: inline-block; }

.data-saved-badge {
  background-color: #F6F6F6;
  color: #1FC881;
  display: inline-block; }

.data-saving-badge {
  background-color: #F6F6F6;
  color: gray;
  display: inline-block; }

.product-module-card-inner-div {
  padding-top: 10px; }

.horizontal-line-without-padding-bottom {
  margin-bottom: 0px; }

.inline-div-with-padding-left-right {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px; }

.breadcrumb-a-tag-link {
  font-size: 12px; }

.input-placeholder::-webkit-input-placeholder {
  color: #c3c3c3; }
