.list-copmponent-parent {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  background-color: white; }

.list-component-remove-button-container {
  position: inherit;
  width: 100%;
  padding-right: 1px;
  z-index: 2; }

.list-component-remove-button {
  float: right;
  margin-bottom: -50px; }
