.documentation-error-wrapper {
  position: absolute;
  bottom: 53px;
  z-index: 1;
  right: 30px; }

.alteration-hooks-name-input {
  font-family: 'Source Code Pro', monospace; }

.Not-published {
  width: 75px;
  height: 20px;
  font-family: Lato;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #7a7a7a; }

.Mr-Price-Device-Copy-2 {
  width: 171px;
  height: 32px;
  font-family: Lato;
  font-size: 24px;
  margin-left: 10px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #3d3d3d; }

.Rectangle-Copy-9 {
  width: '100%';
  box-shadow: 0 0 10px 0 rgba(61, 61, 61, 0.3);
  background-color: #ffffff;
  padding-bottom: 17px; }

.Rectangle {
  width: 24px;
  height: 24px;
  padding-left: 15px;
  padding-right: 31px; }

.IconCross {
  width: 24px;
  height: 24px;
  -o-object-fit: contain;
     object-fit: contain; }
  .IconCross ::before {
    width: 24px;
    height: 24px;
    -o-object-fit: contain;
       object-fit: contain; }

.Branch-of-version-2 {
  font-family: Lato;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #7a7a7a; }

.Draft-product-module-Copy-2 {
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #3d3d3d; }

.module-Type {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #373a3c; }

.template-editor-error-message {
  position: absolute;
  width: 50%;
  bottom: 0px;
  padding-left: 13px;
  padding-right: 13px;
  left: 50%;
  z-index: 1;
  max-height: 200px;
  overflow: scroll; }

.round-button-custom {
  border-radius: 40px;
  height: 40px;
  width: 40px;
  min-width: 40px; }

.dynamic-form-parent {
  height: 100%;
  background-color: white;
  padding: 20px; }

.zero-padding {
  padding: 0px; }

.published-item-time-stamp {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 8px;
  background-color: #e1efff;
  border-radius: 5px; }

.product-module-definition-items {
  height: 40px;
  font-weight: bold;
  font-size: 16px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  background-color: #f6f6f6;
  border-color: #dedede;
  border-style: 1px; }

.product-module-definition-items-policy-lifecycle {
  height: 50px;
  font-weight: bold;
  font-size: 16px;
  color: #373a3c;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  background-color: white;
  border-color: #dedede;
  border-style: 1px; }

.timestamp-parent {
  text-align: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-negative: 1;
      flex-shrink: 1;
  -ms-flex-preferred-size: 0%;
      flex-basis: 0%; }

.preview-button-parent {
  padding-left: 16px;
  float: right;
  border-left: 1px solid #dedede; }

.preview-button-link {
  margin-bottom: 1rem;
  height: 100%;
  color: #4078fa; }

.preview-button-disabled {
  margin-bottom: 1rem;
  height: 100%;
  color: #7d7d7d !important; }

.card-header-product-modules {
  background-color: white;
  font-weight: bold;
  font-size: 16px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }

.card-header-policy-lifecycle {
  background-color: white;
  font-weight: bold;
  font-size: 16px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  height: 50px; }

.in-page-alfred-loading {
  position: fixed;
  left: 50%;
  top: 40%;
  z-index: 2; }

.product-module-definition-item-check {
  display: inline;
  padding-right: 15px; }

.label-policy-lifecycle {
  color: #3d3d3d;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }

.nav-tabs .nav-item {
  margin-bottom: -1px; }

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  font-size: 16px;
  font-weight: bold; }

.nav-tabs .nav-link:hover {
  border-color: white white #4078fa white;
  color: black !important; }

.nav-tabs .nav-link:focus {
  border-color: white white #4078fa white; }

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent; }

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: white white #4078fa white; }

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.add-rule-button-wrapper {
  margin-top: 0px;
  padding-top: 10px;
  padding-bottom: 10px; }

.lifecycle-component-heading {
  font-weight: bold;
  padding-bottom: 10px;
  padding-top: 10px; }

.breadcrumb-a-tag-link {
  font-size: 12px;
  color: #007bff !important;
  cursor: pointer; }

.product-module-definition-key-header {
  font-weight: bold; }

.hr-with-no-margin-bottom-top {
  margin-top: 0px;
  margin-bottom: 0px; }

.product-module-definition-horizontal-card {
  padding-bottom: 0px;
  padding-top: 0px;
  margin-top: 24px;
  margin-bottom: 24px; }

.product-module-definition-horizontal-card-button {
  margin: 0px; }

.product-module-definiton-lifecycle-heading {
  margin-bottom: 0px; }

.container-positioning {
  margin-left: -18px; }

.product-module-heading-description-spacing {
  padding-left: 25px;
  padding-top: 0px;
  margin-top: -8px;
  padding-bottom: 4px; }

.product-module-definition-lifecycle-rules-position {
  padding-top: 16px; }

.navbar-inverse .navbar-nav > li > a:hover {
  background-color: gray; }

.product-module-definition-lifecycle-component-editing-buttons {
  margin-bottom: 0px; }

.product-module-definitions-enable-item-checkbox {
  display: inline-block;
  vertical-align: middle;
  margin-top: -11px; }

.panel-container {
  display: -ms-flexbox;
  display: flex;
  min-height: 300px; }

.panel {
  background: white;
  border: 0px solid gray;
  padding: 0px; }

.resizer {
  width: 3px;
  background: darkGray;
  position: relative;
  cursor: col-resize;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Likely future */ }

.resizer::after,
.resizer::before {
  border-left: 1px solid #333;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-100%);
      transform: translateY(-100%);
  right: 0;
  display: inline-block;
  height: 20px;
  margin: 0 2px; }

.resizer::before {
  left: 0; }

.example-response-no-data-outer {
  width: 100%;
  background: #041e25;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 118px; }

.example-response-no-data-inner {
  display: -ms-flexbox;
  display: flex;
  width: 80px;
  height: 80px;
  margin: 10px;
  padding: 5px;
  border-radius: 80px;
  background: #082d36;
  color: white;
  -ms-flex-align: inherit;
      align-items: inherit;
  -ms-flex-pack: inherit;
      justify-content: inherit; }

.settings-description-label-div {
  display: inline-block;
  vertical-align: middle;
  padding-right: 10px; }

.saved-badge-check-icon-styling {
  margin-right: 5px;
  padding-bottom: 3px; }

.product-module-code-console-rows {
  color: #f8f8f0;
  border-top: 1px solid rgba(73, 102, 108, 0.2) !important; }

.product-module-code-console-rows-error {
  color: #fd3238; }

.product-module-code-console-rows-success {
  color: #1fc881; }

.product-module-code-console-table {
  margin-left: 6px; }

.product-module-code-background-color {
  background-color: #041e25 !important; }

.product-module-code-secondary-header-wrapper {
  color: #f8f8f0;
  margin-bottom: 0px;
  padding-bottom: 7px;
  padding-top: 7px;
  padding-left: 16px;
  border-bottom: 1px solid rgba(73, 102, 108, 0.2); }

.product-module-code-secondary-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-left: auto;
  padding-right: 16px; }

.general-button-center-positioning {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 94px; }

.product-module-code-main-heading {
  display: inline-block;
  margin-top: -6px;
  vertical-align: middle; }

.product-module-code-main-heading-wrapper {
  padding-top: 13px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 8px;
  background-color: #041e25;
  border-bottom: 1px solid #49666c; }

.product-module-code-file-bar {
  margin-top: -13px;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: -8px;
  padding-left: 15px;
  color: #f8f8f090; }

.product-module-code-file-bar-tab {
  display: inline-block;
  padding: 6px 11px;
  margin: 7px 0;
  cursor: pointer;
  border-radius: 0.25rem; }
  .product-module-code-file-bar-tab > svg {
    visibility: hidden;
    margin-left: 8px;
    margin-right: -2px; }
    .product-module-code-file-bar-tab > svg:active {
      height: 14px;
      width: 14px;
      margin-left: 7px;
      margin-right: -3px; }
  .product-module-code-file-bar-tab:hover {
    background-color: rgba(255, 255, 255, 0.05);
    color: #f8f8f095; }
    .product-module-code-file-bar-tab:hover > svg {
      visibility: inherit; }
  .product-module-code-file-bar-tab.active {
    background-color: rgba(255, 255, 255, 0.1);
    color: #f8f8f0; }
    .product-module-code-file-bar-tab.active > svg {
      visibility: inherit; }
  .product-module-code-file-bar-tab.add-button > svg {
    visibility: inherit;
    margin: 0;
    -ms-transform: rotate(-45deg);
        transform: rotate(-45deg); }
    .product-module-code-file-bar-tab.add-button > svg:active {
      margin-left: -1px;
      margin-right: -2px; }

.product-module-code-font-color {
  color: #f8f8f0; }

.product-module-code-close-wrapper {
  float: 'right';
  height: 59px;
  margin-top: 13px;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 13px;
  border-left: 1px solid rgba(61, 61, 61, 0.3); }

.organizations-select-modal-wrapper {
  display: inline-block;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px; }

.organizations-modal-header {
  font-size: 22px;
  text-align: center; }

.organizations-modal-close-button {
  float: right;
  margin-right: 40px; }

.organizations-modal-search-input-wrapper {
  width: 200px;
  float: right;
  padding-bottom: 10px; }

.organizations-modal-pagging-component-wrapper {
  margin-left: -24px; }

.product-module-definition-billing-checkbox-label {
  display: inline-block;
  vertical-align: middle;
  padding-right: 10px;
  margin-top: 11px; }

.product-module-definition-settings-text-input-wrapper {
  padding-bottom: 20px;
  margin-bottom: 0px;
  padding-top: 10px; }

.product-module-settings-input-component-wrapper {
  padding-top: 10px;
  margin-bottom: -2px; }

.product-modules-list {
  margin-top: -20px;
  margin-left: -30px; }

.document-labels-heading {
  padding-bottom: 5px;
  vertical-align: middle;
  padding-right: 10px;
  display: inline-block; }

.scheduled-code-function-name {
  text-align: left;
  padding-left: 4px;
  padding-right: 4px;
  display: inline;
  font-family: 'Source Code Pro', monospace;
  color: #4078fa;
  background-color: #f6f6f6;
  border: 1px solid #dedede;
  border-radius: 4px;
  vertical-align: middle; }

.scheduled-code-function-name-input {
  font-family: 'Source Code Pro', monospace; }

.show-markdown-editor-button-style {
  float: right;
  margin: -5px;
  height: 34px;
  width: 60px; }

.run-log-status-text {
  margin-left: 5px; }

.version-bump-form .form-check {
  padding-left: 0px;
  margin-bottom: 7px; }
  .version-bump-form .form-check input[type="checkbox"] {
    top: -16px; }
  .version-bump-form .form-check span {
    display: inline-block; }

.version-bump-form .btn {
  margin: 0;
  margin-top: 6px;
  width: 100%; }

.version-bump-form .alert {
  margin-top: 10px;
  border: 1px solid #FFAB00;
  border-left-width: 3px; }
