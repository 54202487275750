.template-override {
  padding-left: 24px; }
  .template-override > * > * {
    background-color: #f6f6f6; }

.heading {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between; }

.template-card-overlay {
  visibility: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #3d3d3d;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  border-radius: 0.25rem; }

.card:hover .template-card-overlay {
  visibility: visible; }

.template-editor-white {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  display: -ms-flexbox;
  display: flex;
  margin-top: 55px;
  overflow-y: scroll;
  border-top: 1px solid #cccccc; }
  .template-editor-white .CodeMirror-gutters {
    width: 50px !important; }
  .template-editor-white .CodeMirror-sizer {
    margin-left: 63px;
    padding-left: 10px; }
  .template-editor-white .code-editor {
    max-width: 50%;
    -ms-flex: 1;
        flex: 1;
    height: 100%; }
    .template-editor-white .code-editor .CodeMirror {
      cursor: text;
      height: 100%;
      max-height: calc(87vh);
      overflow-y: scroll;
      overflow-x: hidden;
      margin: 0; }
  .template-editor-white .merge-vars-code-window {
    width: 100%;
    -ms-flex-direction: row;
        flex-direction: row;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden; }
    .template-editor-white .merge-vars-code-window .CodeMirror {
      cursor: text;
      -ms-flex: 1;
          flex: 1;
      overflow-y: scroll;
      margin: 0; }
  .template-editor-white .template-preview {
    max-width: 100%;
    overflow-y: scroll;
    overflow-x: inherit;
    -ms-flex: 1;
        flex: 1;
    background: white; }
    .template-editor-white .template-preview iframe {
      border: none !important;
      outline: none;
      height: 100%;
      width: 100%; }

.template-editor {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  background-color: #f6f6f6; }
  .template-editor .code-editor {
    max-width: 50%;
    -ms-flex: 1;
        flex: 1;
    height: 100%; }
    .template-editor .code-editor .CodeMirror {
      cursor: text;
      height: 100%;
      max-height: calc(87vh);
      overflow-y: scroll;
      overflow-x: hidden;
      margin: 0; }
  .template-editor .merge-vars-code-window {
    width: 100%;
    -ms-flex-direction: row;
        flex-direction: row;
    height: 100%;
    overflow: scroll; }
    .template-editor .merge-vars-code-window .CodeMirror {
      cursor: text;
      -ms-flex: 1;
          flex: 1;
      overflow-y: scroll;
      margin: 0;
      height: 100%; }
  .template-editor .template-preview {
    max-width: 100%;
    overflow-y: scroll;
    overflow-x: inherit;
    -ms-flex: 1;
        flex: 1; }
    .template-editor .template-preview iframe {
      border: none !important;
      outline: none;
      height: 100%;
      width: 100%; }

.full-screen-modal {
  max-width: 100vw;
  margin: 0; }
  .full-screen-modal .modal-content {
    height: 100vh;
    border-radius: 0px;
    border: none !important; }
  .full-screen-modal .modal-header {
    height: 54px;
    min-height: 54px;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    border: none;
    box-shadow: 0 0 10px 0 rgba(61, 61, 61, 0.3);
    z-index: 1; }
    .full-screen-modal .modal-header .modal-title {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      width: 100%; }
    .full-screen-modal .modal-header .centered-text {
      height: 54px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
          justify-content: center;
      -ms-flex-align: center;
          align-items: center; }
      .full-screen-modal .modal-header .centered-text > * {
        margin: 0 !important; }
  .full-screen-modal .modal-body {
    overflow: hidden; }
  .full-screen-modal .modal-footer {
    height: 53px;
    min-height: 53px;
    -ms-flex-pack: start;
        justify-content: flex-start;
    width: 100%;
    padding: 0;
    overflow: hidden;
    border: none;
    box-shadow: 0 0 10px 0 rgba(61, 61, 61, 0.3);
    z-index: 1; }
    .full-screen-modal .modal-footer .full-icon {
      background: #4078fa; }
    .full-screen-modal .modal-footer .text {
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: #373a3c;
      margin: 0 16px 0 16px; }
      .full-screen-modal .modal-footer .text small {
        margin-left: 20px;
        font-size: 12px;
        color: #7d7d7d; }
    .full-screen-modal .modal-footer .right-content {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      margin-left: auto;
      padding-right: 8px; }
  .full-screen-modal .full-icon {
    width: 53px;
    height: 53px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    background: #f6f6f6; }
    .full-screen-modal .full-icon svg {
      cursor: pointer; }

.no-margin {
  margin: 0; }

.no-padding {
  padding: 0; }

.input-heading {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  color: #373a3c; }

.merge-vars-code-window {
  height: 100px; }

.merge-vars-bar-menu {
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 4px;
  margin-bottom: -13px;
  margin-top: -11px;
  background: transparent;
  position: absolute;
  width: 100%; }

.merge-vars-button {
  width: 112px;
  height: 32px;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(61, 61, 61, 0.3);
  background-color: #ffffff;
  border-color: white; }

.merge-vars-modal {
  position: absolute;
  background-color: white;
  top: 0px;
  margin-top: 10%;
  -webkit-margin-after: auto;
          margin-block-end: auto;
  border-radius: 5px;
  left: 0px;
  width: 40%;
  right: 0px;
  margin-right: auto;
  margin-left: auto; }

.pdf-viewer-wrapper {
  margin-top: 25px;
  height: 100%;
  overflow: scroll;
  margin-bottom: 20px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0px;
  right: 0px; }

.completed-card-rule-heading {
  display: inline-block;
  padding-left: 10px; }

.product-modules-form-group-align {
  margin-left: -8px; }
