.panel-container-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

.panel-row {
  background: white;
  border: 0px;
  padding: 0px; }

.resizer-row {
  z-index: 999;
  width: 100%;
  display: list-item;
  background: #49666C;
  position: relative;
  cursor: row-resize;
  height: 3px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Likely future */ }

.resizer-row::after,
.resizer-row::before {
  border-bottom: 1px solid #333;
  position: absolute;
  left: 50%;
  -ms-transform: translateX(-100%);
      transform: translateX(-100%);
  right: 0;
  height: 3px;
  margin: 0 2px; }

.resizer-row::before {
  left: 0; }
