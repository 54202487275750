.panel-container-column {
  display: -ms-flexbox;
  display: flex; }

.panel-column {
  background: white;
  border: 0px;
  padding: 0px; }

.resizer-column {
  z-index: 999;
  width: 3px;
  background: #49666C;
  position: relative;
  cursor: col-resize;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Likely future */ }

.resizer-column::after,
.resizer-column::before {
  border-left: 1px solid #333;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-100%);
      transform: translateY(-100%);
  right: 0;
  display: inline-block;
  height: 20px;
  margin: 0 2px; }

.resizer-column::before {
  left: 0; }
